<template>
  <Layout :top-bar-title="title" :sections="sections" :baseLayoutInfoUrl="'/base-layout-info/'">
    <ChainsTable/>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import ChainsTable from '../components/tables/ChainsTable'
import sections from '../../dido/views/defaultNavigationDrawerSections'

export default {
  components: { ChainsTable, Layout },
  data () {
    return {
      sections: sections,
      title: 'Cadenas'
    }
  }
}
</script>

<style scoped>

</style>
