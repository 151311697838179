<template>
  <div>
    <div class="float-right">
     <router-link :to="{ name: 'ChainsDetail',  params: { id: 'new'}}" tag="button">
          <v-btn color="primary" dark class="mb-2 mr-3 elevation-0 btn pull-right mt-6 ml-2">
              <v-icon>mdi-plus</v-icon>
              Nuevo
          </v-btn>
     </router-link>
    </div>
    <CrudTable
      v-bind="crudTableConfig"
      :storeModule="storeModule"
      :addItems=false
      @save="onDistributorEdition"
    >
      <template v-slot:form="{close}">
        <ChainsForm showHeader @save="afterCreation" @close="close"/>
      </template>
      <template v-slot:item.external_id="{ item }">
        <router-link :to="{ name: 'ChainsDetail', params: { id: item.external_id }}">
          {{ item.external_id }}
        </router-link>
      </template>
    </CrudTable>
  </div>
</template>

<script>
import Lset from 'lodash/set'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import ChainsForm from '../../components/forms/distributor/ChainsForm'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ChainsTable',
  components: { ChainsForm, CrudTable },

  data () {
    return {
      storeModule: 'chainsCatalog'
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    })
  },
  methods: {
    ...mapActions('chainsCatalog', ['reloadItemList']),
    afterCreation () {
      this.reloadItemList()
      console.log(this.items)
    },
    onDistributorEdition (data) {
      const updatedItem = Lset(data.item, data.header, data.value)

      this.$store
        .dispatch(`${this.storeModule}/postItem`, updatedItem)
        .then(
          () => this.reloadItemList()
        )
    }
  }
}
</script>

<style scoped>

</style>
