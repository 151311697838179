<template>
  <v-form @submit.prevent="saveForm" ref="form">
    <BreadcrumbsField :items="itemsBreadcrumbs"/>
    <v-card>
      <v-card-title>
        <h2 class="px-3" v-if="this.currentItem.external_id">{{this.currentItem.external_id}}</h2>
        <h2 class="px-3" v-else>NUEVA CADENA</h2>
      </v-card-title>
      <CustomView
        view-name="chains_form_view"
        :value="this.currentItem"
        :options="options"
      ></CustomView>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="cancel" color="primary" text @click="closeForm">{{ $t('general.cancel') }}</v-btn>
        <v-btn class="save" color="primary" @click="saveForm">{{ $t('general.save') }}</v-btn>
      </v-card-actions>
      <PrettyAlert :text="textAlert" v-model="showAlert"/>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { validationMixin } from '@/apps/core/mixins/validations'
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import CustomView from '../../../../../lib/uncustomui/components/CustomView'
export default {
  name: 'ChainsForm',
  props: {
    showHeader: Boolean
  },
  components: { PrettyAlert, BreadcrumbsField, CustomView },
  mixins: [validationMixin],
  data () {
    return {
      textAlert: '',
      showAlert: false,
      itemsBreadcrumbs: [
        {
          text: 'LISTA DE CADENAS',
          disabled: false,
          path: '/catalogs/chains'
        },
        {
          text: 'CADENA',
          disabled: true
        }
      ]
    }
  },
  computed: {
    ...mapState('chainsCatalog', ['currentItem'])
  },
  methods: {
    ...mapActions('chainsCatalog', ['postItem', 'updateCurrentItem']),
    closeForm () {
      this.$refs.form.reset()
      this.updateCurrentItem({})
      this.$emit('close')
    },

    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },

    saveForm () {
      if (this.$refs.form.validate()) {
        this.postItem(this.currentItem)
          .then(
            () => {
              this.$emit('save')
              this.$refs.form.reset()
              this.closeForm()
            })
          .catch((err) => {
            console.error(err)
            this.showAlertAction('Error al guardar')
          })
      }
    }
  },
  mounted () {
  }
}
</script>
